import React from 'react';
import terminal from '../assets/OPPAInomics.png'

const About = () => {
  return (
    <div className='w-full bg-black text-white text-center'>
      <div className='max-w-[1240px] mx-auto px-4 py-16 '>
        <div id="roadmap">
        <h1>TRUMP NO. 1</h1> <br /><br />
        <p className='text-3xl'>Trump is the 47th President of USA and the No. 1 Crypto President! Trump is the one and only No. 1! $TRUMP1</p>


        <img className='justify-center' src={terminal} alt="/" />
     
        </div>
      </div>
    </div>
  );
};
export default About;
